<template>
  <div id="menu">
    <div class="wrap Plf10">
      <div v-for="(item, index) in menuList" :key="index">
        <span>{{ item.title }}</span>
        <router-link
          class="F14"
          :class="[$route.name == items.link ? 'orange' : '', routeName(items.link)]"
          v-for="(items, index) in item.menus"
          :key="index"
          :to="{ name: items.link, params: { userId: 123 } }"
          >{{ items.menu }}</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Menu",
  data() {
    return {
      supplierMenu: [
        {
          title: "信息管理",
          menus: [
            {
              menu: "账号认证",
              link: "Authentication",
            },
            {
              menu: "会员信息",
              link: "Information",
            },
            {
              menu: "密码重置",
              link: "PhoneVerfiy",
            },
          ],
        },
        {
          title: "个人中心",
          menus: [
            {
              menu: "我的账户",
              link: "MyAccount",
            },
          ],
        },
        {
          title: "订单管理",
          menus: [
            {
              menu: "订单信息",
              link: "MyOrder",
            },
          ],
        },
        {
          title: "商品管理",
          menus: [
            {
              menu: "商品信息",
              link: "GoodsList",
            },
          ],
        },
      ],
      purchaserMenu: [
        {
          title: "个人中心",
          menus: [
            {
              menu: "我的账户",
              link: "PurchaserMyMoney",
            },
            {
              menu: "账号认证",
              link: "Authentication",
            },
            {
              menu: "会员信息",
              link: "Information",
            },
            {
              menu: "密码重置",
              link: "PhoneVerfiy",
            },
            {
              menu: "收货地址",
              link: "PurchaserShipAddr",
            },
          ],
        },
        {
          title: "订单中心",
          menus: [
            {
              menu: "我的订单",
              link: "MyOrder",
            },
            {
              menu: "开票管理",
              link: "HistoryInvoice",
            },
          ],
        },
      ],
      menuList: [],
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },

  created() {
    this.menuShow();
  },
  methods: {
    menuShow() {
      // console.log(this.userInfo)
      if (this.userInfo.roleType == "1") {
        this.menuList = this.supplierMenu;
      } else if (this.userInfo.roleType == "2") {
        this.menuList = this.purchaserMenu;
      }
    },
    routeName(link) {
      let routeName = "";
      let matched = this.$route.matched;
      for(let x = 0; x < matched.length; x++) {
        let result = matched[x];
        if (result.name == link) {
          routeName = "orange"
        }
      }
      let temp = {
        PurchaserMyMoney: [ 'PurchaserMyMoney', 'PurchaserExpense', 'PurchaserRecharge' ],
        MyAccount: [ 'WithdrawalRecord', 'AllRecord', 'Audit', 'WithdrawalOf', 'ToAccount', 'WithdrawalFailure', 'DepositPwdChange' ]
      }
      if (temp[link]) {
        if (temp[link].indexOf(this.$route.name) > 0) {
          routeName = "orange"
        }
      }
      return routeName
    }
  },
};
</script>

<style lang="scss" scoped>
.wrap {
  padding-top: 5px;
  span {
    display: inline-block;
    padding-top: 15px;
    font-weight: bold;
    font-size: 16px;
    width: 64px;
  }
  a {
    display: inline-block;
    padding-top: 15px;
    font-size: 14px;
    width: 64px;
  }
}
</style>
