<template>
  <div class="roleheader bg-orange">
    <div class="contain">
      <div class="left">
        <el-image src="/img/supplier/logo.png" class="wid100 logo"></el-image>
        <span class="co_fff Plf15">{{ websiteInfo.name }}</span>
      </div>
      <div class="right">
        <div class="search">
          <div class="searchinput">
            <el-input
              class="searchinput bd-orange"
              placeholder="搜索你需要的商品"
              v-model.trim="goodsName"
              @keyup.enter.native="FTSSearch()"
            ></el-input>
          </div>
          <div class="icon bg_e2 wid40 het40 F18">
            <i class="el-icon-search curPointer" @click="FTSSearch"></i>
          </div>
        </div>
        <div class="shop">
          <slot name="shopCart">
            <router-link :to="{ name: 'ShopCart' }">
              <el-badge
                :value="shopCarInfo.total"
                class="item"
                :hidden="shopCarInfo.total == 0 ? true : false"
              >
                <button class="gouwu">
                  <span class="iconfont icon-gouwuchekong orange"></span>
                  我的购物车
                </button>
              </el-badge>
            </router-link>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "roleHeader",
  computed: {
    ...mapGetters(["websiteInfo", "shopCarInfo"]),
  },
  data() {
    return {
      goodsName: "",
    };
  },
  methods: {
    FTSSearch() {
      if (this.goodsName == "") {
        this.$router.push({
          name: "index",
        });
      } else {
        this.$router.push({
          name: "FTSGoods",
          query: {
            goodsName: this.goodsName,
          },
        });
        this.$emit("FTSSearch", {
          goodsName: this.goodsName,
        });
      }
    },
  },
};
</script>
<style lang="less" scoped>
.contain {
  padding: 15px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .left {
    .logo {
      vertical-align: middle;
    }
    span {
      font-size: 30px;
      vertical-align: middle;
      font-weight: bold;
    }
  }
  .right {
    .search {
      display: inline-block;
      overflow: hidden;
      vertical-align: middle;
      .searchinput {
        float: left;
        width: 200px;
      }
      .icon {
        float: right;
        line-height: 40px;
        text-align: center;
        border-radius: 0 3px 3px 0;
        margin-top: 1px;
      }
    }
    .shop {
      float: right;
      vertical-align: middle;
      margin-left: 10px;
      .gouwu {
        cursor: pointer;
        font-size: 14px;
        height: 42px;
        padding: 0 10px;
        background-color: #fff;
        border: 1px solid#d2d2d2;
        span {
          font-size: 22px;
        }
      }
    }
  }
}
</style>