<template>
  <div class="centreinformation">
    <div class="contain">
      <el-container>
				<el-aside width="116px">
          <div class="menus">
            <slot name="centermenu"></slot>
          </div>
				</el-aside>
				<el-container>
          <div class="wrap">
            <slot name="relat"></slot>
          </div>
				</el-container>
			</el-container>
    </div>
  </div>
</template>

<script>

export default {
  name: "CentreInformation",

  data() {
    return {

    }
  }

}
</script>
<style lang="scss" scoped>
.wrap {
  width: 100%;
}
</style>