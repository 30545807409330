import { render, staticRenderFns } from "./centreInformation.vue?vue&type=template&id=367da7fb&scoped=true&"
import script from "./centreInformation.vue?vue&type=script&lang=js&"
export * from "./centreInformation.vue?vue&type=script&lang=js&"
import style0 from "./centreInformation.vue?vue&type=style&index=0&id=367da7fb&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "367da7fb",
  null
  
)

export default component.exports