<template>
  <div class="sohohome bg_f4f4">
    <top class="top"></top>
    <role-header></role-header>
    <centre-information>
      <div slot="centermenu">
        <menu-component></menu-component>
      </div>
      <div slot="relat">
        <router-view></router-view>
      </div>
    </centre-information>
    <bottom :bgColor="'#fff'"></bottom>
  </div>
</template>
<script>
import Top from "@/components/top";
import Bottom from "@/components/bottom";
import CentreInformation from "@/components/supplier/centreInformation.vue";
import MenuComponent from "@/components/supplier/menuComponent.vue";
import RoleHeader from "@/components/roleHeader.vue";

export default {
  data() {
    return {};
  },
  components: {
    Top,
    Bottom,
    CentreInformation,
    MenuComponent,
    RoleHeader,

  },
};
</script>
<style scoped lang="scss">
</style>